import { Row, Col, Divider } from "antd";
import { LockOutlined } from "@ant-design/icons";
import Link from "next/link";

import * as colors from "../constants/colors";
import AuthLayout from "../components/layout/AuthLayout";
import LoginForm from "../components/forms/LoginForm";

const LoginPage: React.FC = () => {
  return (
    <AuthLayout>
      <Row justify="center">
        <p className="text">Log in om door te gaan</p>
        <Col xs={24} sm={24} md={20} lg={20}>
          <LoginForm />
        </Col>
        <Link href="/forget-password">
          <p style={{ cursor: "pointer" }} className={`text-left`}>
            <LockOutlined /> Uw wachtwoord vergeten ?
          </p>
        </Link>
      </Row>
      <Divider />
      <p className={`text-center`}>
        Heb je nog geen account ?{" "}
        <Link href="/register">
          <a style={{ color: colors.SECONDARY }}>Meld u hier aan</a>
        </Link>
      </p>
    </AuthLayout>
  );
};

export default LoginPage;
