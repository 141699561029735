import { Button, Form, Input, notification } from "antd";
import { useState, useContext, useEffect } from "react";
import AuthContext from "@context/AuthContext";

import * as colors from "../../constants/colors";

const LoginForm = () => {
  const { login, errorCode } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("Hey", errorCode);
    if (errorCode) {
      if (errorCode === 400) {
        notification.error({
          message: "Ongeldige Inloggegvens",
          duration: 5,
          description:
            "Ongeldige gebruikersnaam of wachtwoord. Probeer het nog een keer",
        });
      }

      setLoading(false);
    }
  }, [errorCode]);

  const initialCredential = {
    email: "",
    password: "",
  };

  const onLogin = async (values: typeof initialCredential) => {
    try {
      setLoading(true);
      await login({ email: values.email, password: values.password });
    } catch (error) {
      notification.error({ message: "Verkeerde Inloggegevens" });
      setLoading(false);
    }
  };

  return (
    <Form
      layout="vertical"
      name="login-form"
      initialValues={initialCredential}
      onFinish={onLogin}
    >
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            message: "Voer uw e-mailadres in",
          },
          {
            type: "email",
            message: "Voer een valideer e-mailadres in!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="password"
        label="Wachtwoord"
        rules={[
          {
            required: true,
            message: "Voer uw wachtwoord in",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item>
        <Button
          style={{ backgroundColor: colors.PRIMARY, border: 0 }}
          type="primary"
          htmlType="submit"
          block
          loading={loading}
        >
          Log In
        </Button>
      </Form.Item>
    </Form>
  );
};

export default LoginForm;
